// Generated by Framer (c75d380)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["UAdRLgjlG"];

const serializationHash = "framer-bZVIA"

const variantClassNames = {UAdRLgjlG: "framer-v-61p38e"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, tagColor, tagTitle, width, ...props}) => { return {...props, lweuQoUmq: tagTitle ?? props.lweuQoUmq ?? "web design", NL6pVsZn0: tagColor ?? props.NL6pVsZn0 ?? "rgba(144, 79, 255, 0.4)"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tagColor?: string;tagTitle?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, NL6pVsZn0, lweuQoUmq, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "UAdRLgjlG", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-61p38e", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"UAdRLgjlG"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: NL6pVsZn0, borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.div style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-font-weight": "500", "--framer-line-height": "14px", "--framer-text-color": "var(--extracted-tcooor, var(--token-96ef5b7b-ed6e-4a26-9b93-c0662014d76c, rgb(0, 0, 0)))"}}>web design</motion.div></React.Fragment>} className={"framer-1rwumhx"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"Ed68K3M1j"} style={{"--extracted-tcooor": "var(--token-96ef5b7b-ed6e-4a26-9b93-c0662014d76c, rgb(0, 0, 0))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={lweuQoUmq} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-bZVIA[data-border=\"true\"]::after, .framer-bZVIA [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bZVIA.framer-10xpnfq, .framer-bZVIA .framer-10xpnfq { display: block; }", ".framer-bZVIA.framer-61p38e { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: flex-start; overflow: visible; padding: 4px 8px 4px 8px; position: relative; width: min-content; }", ".framer-bZVIA .framer-1rwumhx { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bZVIA.framer-61p38e { gap: 0px; } .framer-bZVIA.framer-61p38e > * { margin: 0px; margin-bottom: calc(8px / 2); margin-top: calc(8px / 2); } .framer-bZVIA.framer-61p38e > :first-child { margin-top: 0px; } .framer-bZVIA.framer-61p38e > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"NL6pVsZn0":"tagColor","lweuQoUmq":"tagTitle"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framert82knx6od: React.ComponentType<Props> = withCSS(Component, css, "framer-bZVIA") as typeof Component;
export default Framert82knx6od;

Framert82knx6od.displayName = "Component/Tag";

Framert82knx6od.defaultProps = {height: 200, width: 200};

addPropertyControls(Framert82knx6od, {NL6pVsZn0: {defaultValue: "rgba(144, 79, 255, 0.4)", title: "Tag Color", type: ControlType.Color}, lweuQoUmq: {defaultValue: "web design", displayTextArea: false, title: "Tag Title", type: ControlType.String}} as any)

addFonts(Framert82knx6od, [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZ1rib2Bg-4.woff2", weight: "500"}])